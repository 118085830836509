/* global jQuery */
let LoginPage = function() {
    let login = jQuery('#m_login');

    let showMsg = function(form, type, msg) {
        // eslint-disable-next-line
        let alert = jQuery('<div class="m-alert m-alert--outline alert alert-' +
            type +
            ' alert-dismissible" role="alert">' +
            '<button type="button" class="close" data-dismiss="alert" aria-label="Close"></button><span></span></div>');

        form.find('.alert').remove();
        alert.prependTo(form);
        alert.animateClass('fadeIn animated');
        alert.find('span').html(msg);
    };

    let displaySignInForm = function() {
        login.removeClass('m-login--forget-password');
        login.removeClass('m-login--change-password');

        login.addClass('m-login--signin');
        login.find('.m-login__signin').animateClass('flipInX animated');
    };

    let displayForgetPasswordForm = function() {
        login.removeClass('m-login--signin');
        login.removeClass('m-login--change-password');

        login.addClass('m-login--forget-password');
        login.find('.m-login__forget-password').animateClass('flipInX animated');
    };

    let handleFormSwitch = function() {
        jQuery('#m_login_forget_password').click(function(e) {
            e.preventDefault();
            displayForgetPasswordForm();
        });

        jQuery('#m_login_forget_password_cancel').click(function(e) {
            e.preventDefault();
            displaySignInForm();
        });

        jQuery('#m_login_change_password_cancel').click(function(e) {
            e.preventDefault();
            displaySignInForm();
        });
    };

    let handleSignInFormSubmit = function() {
        jQuery('#m_login_signin_submit').click(function(e) {
            e.preventDefault();
            let btn = jQuery(e.target);
            let form = btn.closest('form');

            form.validate({
                rules: {
                    _email: {
                        required: true
                    },
                    _password: {
                        required: true
                    }
                }
            });

            if (!form.valid()) {
                return;
            }

            btn.addClass('m-loader m-loader--right m-loader--light').
            attr('disabled', true);

            form.ajaxSubmit({
                url: '',
                success: function(response, status, xhr, $form) {
                    btn.removeClass('m-loader m-loader--right m-loader--light').
                    attr('disabled', false);
                    if (response.status === 'error') {
                        showMsg(form, 'danger', response.error_message);
                    } else {
                        location.href = response.redirect_url;
                    }
                }
            });
        });
    };

    let handleForgetPasswordFormSubmit = function() {
        jQuery('#m_login_forget_password_submit').click(function(e) {
            e.preventDefault();

            let btn = jQuery(e.target);
            let form = btn.closest('form');

            btn.addClass('m-loader m-loader--right m-loader--light').
            attr('disabled', true);

            form.ajaxSubmit({
                url: form.attr('action'),
                method: 'POST',
                success: function(response, status, xhr, $form) {
                    btn.removeClass('m-loader m-loader--right m-loader--light').
                    attr('disabled', false);
                    form.clearForm();
                    form.validate().resetForm();

                    displaySignInForm();
                    showMsg(
                        $('.m-login__signin .m-login__form'),
                        'info',
                        Translator.trans('user.password_reset_email_sent', {}, 'users')
                    );
                }
            });
        });
    };

    return {
        // public functions
        init: function() {
            handleFormSwitch();
            handleSignInFormSubmit();
            handleForgetPasswordFormSubmit();
        }
    };
}();

jQuery(document).ready(function() {
    LoginPage.init();
});
